
import {Component, Vue} from 'vue-property-decorator';

// Config
import { RouteNames } from "@/config/router.config";
import { track, TrackingEventType } from "@/config/tracking.config";

// Components
import CartEntry from '@/components/cart-entry.component.vue';
import LoadingSpinner from '@/components/loading-spinner.component.vue';
import PageHeader from '@/components/page-header.component.vue';
import QuantityPicker from '@/components/quantity-picker.component.vue';

// Models
import CartModel from '@/models/cart.model';

@Component({
  components: {
    CartEntry,
    LoadingSpinner,
    PageHeader,
    QuantityPicker
  }
})
export default class CartView extends Vue {

  get cart(): CartModel {
    return this.$store.state.cart.cart;
  }

  get loading() {
    return this.$store.state.cart.loading
  }

  get isCartNullOrEmpty() {
    return this.cart === null || this.cart.entries.length === 0;
  }

  protected created() {
    track(TrackingEventType.ViewContent, {
      content_category: RouteNames.Cart
    });
  }

  trackCheckout() {
    track(TrackingEventType.InitiateCheckout, {
      content_category: RouteNames.Cart,
      content_ids: this.cart.entries.map(entry => entry.id),
      contents: this.cart.entries.map(entry => ({
        id: entry.id,
        quantity: entry.quantity
      })),
      num_items: this.cart.entries.length
    });
  }
}
