
import { Component, Prop, Vue } from 'vue-property-decorator';

// Components
import QuantityPicker from '@/components/quantity-picker.component.vue';

// Models
import CartEntryModel from '@/models/cart-entry.model';

@Component({
  components: {
    QuantityPicker
  }
})
export default class CartEntry extends Vue {

  @Prop({
    type: Object,
    required: true
  })
  entry!: CartEntryModel;

  get showVariants(): boolean {
    return this.entry.variants.length > 1 || this.entry.variants[0].value !== 'Default Title';
  }

  get totalPrice(): number {
    return this.entry.price * this.entry.quantity;
  }

  handleQuantityChanged(productId: string, quantity: number) {
    this.$store.dispatch('cart/setCartEntryQuantity', {
      productId,
      quantity
    });
  }

  removeCartEntry(productId: string) {
    this.$store.dispatch('cart/removeCartEntry', {
      productId
    })
  }

}
